import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';

import './navbar.css';
import logo from '../../assets/img/output-onlinepngtools.png'


const Menu = () => (
  <>
  <p><a href="#home">Home</a></p>
  <p><a href="#wdimaco">What is DIMACO</a></p>
  <p><a href="#posibility">Open AI</a></p>
  <p><a href="#features">Case Studies</a></p>
  <p><a href="#blog">Library</a></p>
  </>
)
const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false)

  return (
    <div className='dimaco__navbar'>
      <div className='dimaco__navbar-links'>
        <div className='dimaco__navbar-link_logo'>
          <img src={ logo } alt="logo" />
        </div>
        <div className='dimaco__navbar-links_container'>
          <Menu />
        </div>
      </div>
      <div className='dimaco__navbar-sign'>
        <p>sign in</p>
        <button type='button'>sign up</button>
      </div>
      <div className='dimaco__navbar-menu'>
        {toggleMenu
          ? <RiCloseLine color='#FFF' size={27} onClick={()=> setToggleMenu(false)} />
          : <RiMenu3Line color='#FFF' size={27} onClick={()=> setToggleMenu(true)} />
        }
        {toggleMenu && (
          <div className='dimaco__navbar-menu_container scale-up-center'>
            <div className='dimaco__navbar-menu-container-length'>
            <Menu />
                <div className='dimaco__navbar-menu_container-links-sign'>
                  <p>sign in</p>
                <button type='button'>sign up</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Navbar
